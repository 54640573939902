import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from 'react-router-dom';
import { API } from "aws-amplify";
import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
//install before import: npm install @mui/x-data-grid
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, GridToolbar, GridToolbarQuickFilter, gridClasses, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import axios from 'axios';
import {currencyList} from './Lists/currencyList';
import {nationalityList} from './Lists/nationalityList';
import RateReviewIcon from '@mui/icons-material/RateReview';
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment/moment";

const config = require('../config.json')

export default function Notes() {
  //const { route } = useAuthenticator((context) => [context.route]);
  const { user, signOut } = useAuthenticator((context) => [
    context.user,
    context.signOut,
  ]);
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [deletingRow, setDeletingRow] = React.useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userEmail, setUserEmail] = useState('');
  const [isAdmin, setAdmin] = useState(false);
  const [enableGoogle, setEnableGoogle] = useState(false);
  const [monitorFreq, setMonitorFreq] = useState('Disable');
  
  const handleClickOpen = () => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  }
  const handleConfirmationClose = () => {
    setOpen(false);
    handleDelete(deletingRow);
  }
  const handleReview = (newRow) => {
    const { Customer, English_name, Chinese_name } = newRow;
    const url = (Customer || English_name || Chinese_name)
      ? '/monitor/' + (Customer || English_name || Chinese_name) + '/' + (English_name || Chinese_name) + '/' + (Chinese_name || English_name)
      : '';
    console.log(url);
    navigate(url)
  } 
  const handleDeleteConfirmation = (newRow) => {
    setDeletingRow(newRow);
    handleClickOpen();
  }
  const handleDelete = (newRow) => {
    if (newRow == null) {
      return;
    }
    if (!isAdmin && newRow.English_name !== '' && (typeof(newRow.English_filename) == 'undefined' || newRow.English_filename === 'processing...')) {
      setSnackbar({ children: 'Customer: ' + newRow.Customer + ' Name: ' + newRow.English_name + '  ' + newRow.Chinese_name + ' is processing. Please delete it later.', severity: 'warning' });
      return;
    }
    if (!isAdmin && newRow.Chinese_name !== '' && (typeof(newRow.Chinese_filename) == 'undefined' || newRow.Chinese_filename === 'processing...')) {
      setSnackbar({ children: 'Customer: ' + newRow.Customer + ' Name: ' + newRow.English_name + '  ' + newRow.Chinese_name + ' is processing. Please delete it later.', severity: 'warning' });
      return;
    }
    let response = axios
      .delete(
        `${config.api.invokeUrl}`,
        {
          data: {
            User: newRow.User,
            Case: newRow.Case,
          },
          headers: {
            "content-type": "application/json",
            Authorization: `${user.signInUserSession.idToken.jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.status === 204 || res.status === 201) {
          setSnackbar({ children: 'Customer: ' + newRow.Customer + ' Name: ' + newRow.English_name + '  ' + newRow.Chinese_name + ' deleted successfully. ', severity: 'success' });
        }
        else {
          setSnackbar({ children:  'Customer: ' + newRow.Customer + ' Name: ' + newRow.English_name + '  ' + newRow.Chinese_name + ' delete failed.', severity: 'error' });
        }
        fetchCases();
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          //signOut();
          //navigate("/login");
          window.location.reload(true);
        }
        console.log(err);
        setSnackbar({ children:  'Customer: ' + newRow.Customer + ' Name: ' + newRow.English_name + '  ' + newRow.Chinese_name + ' delete exception.', severity: 'error' });
      });
  };

  const handleFreshButton = async (newRow) => {
    console.log(JSON.stringify(newRow));
    const hoursDiff = (new Date() - new Date(newRow.Create_time))/(1000*60*60);
    if (hoursDiff < 24) {
      setSnackbar({children: 'Refresh is not necessary with 24 hours.', severity: 'warning' });
      return;
    }

    let response = await axios
      .patch(
        `${config.api.invokeUrl}`,
        {
          //api here
          User: newRow.User,
          Case: newRow.Case,
          English_name: newRow.English_name,
          Chinese_name: newRow.Chinese_name,
          Customer: newRow.Customer,
          Type: newRow.Type,
          Email: newRow.Email,
          Nationality: newRow.Nationality,
          DOB: newRow.DOB,
          Gender: newRow.Gender,
          Ongoing_monitor: newRow.Ongoing_monitor,
          English_filename: "",
          Chinese_filename: "",
          Acuris_English: "",
          Create_time: (new Date()).toISOString().slice(0, 19).replace('T', ' '), //Pass Create_time so that both Acuris and Image_Scraper receives the same time for audit
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `${user.signInUserSession.idToken.jwtToken}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 204 || res.status === 201) {
          setSnackbar({ children: 'Customer: ' + newRow.Customer + ' Name: ' + newRow.English_name + '  ' + newRow.Chinese_name + ' refreshed successfully. ', severity: 'success' });
        }
        else {
          setSnackbar({ children:  'Customer: ' + newRow.Customer + ' Name: ' + newRow.English_name + '  ' + newRow.Chinese_name + ' refresh failed.', severity: 'error' });
        }
        fetchCases();
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          //signOut();
          //navigate("/login");
          window.location.reload(true);
        }
        console.log(err);
        setSnackbar({ children:  'Customer: ' + newRow.Customer + ' Name: ' + newRow.English_name + '  ' + newRow.Chinese_name + ' refresh exception.', severity: 'error' });
      });
  };

  const processRowUpdate = React.useCallback( (newRow) => {
    console.log(JSON.stringify(newRow))
    if (newRow.IDExpiry) {
      newRow.IDExpiry = moment(newRow.IDExpiry).format("YYYY-MM-DD");
    }
    axios.patch(`${config.api.invokeUrl}`, { //api here
      User: newRow.User,
      Case: newRow.Case,
      Email: newRow.Email, Contact_number: newRow.Contact_number, Mobile: newRow.Mobile, Nationality: newRow.Nationality, 
      DOB: newRow.DOB, Gender: newRow.Gender, IDType: newRow.IDType, IDNumber: newRow.IDNumber, IDExpiry: newRow.IDExpiry, 
      Residential_address: newRow.Residential_address, Permanent_address: newRow.Permanent_address, Correspondence_address: newRow.Correspondence_address, 
      Tax_resident_country: newRow.Tax_resident_country, Tax_number: newRow.Tax_number, Tax_type: newRow.Tax_type, Tax_country: newRow.Tax_country,
      Annual_income_source_1: newRow.Annual_income_source_1, Source_of_wealth_1: newRow.Source_of_wealth_1, Currency_1: newRow.Currency_1, Estimated_networth_1: newRow.Estimated_networth_1,
      Annual_income_source_2: newRow.Annual_income_source_2, Source_of_wealth_2: newRow.Source_of_wealth_2, Currency_2: newRow.Currency_2, Estimated_networth_2: newRow.Estimated_networth_2,
      Annual_income_source_3: newRow.Annual_income_source_3, Source_of_wealth_3: newRow.Source_of_wealth_3, Currency_3: newRow.Currency_3, Estimated_networth_3: newRow.Estimated_networth_3,
      Employer_name: newRow.Employer_name, Occupation: newRow.Occupation, Position: newRow.Position, Proof_of_professional_investment_asset: newRow.Proof_of_professional_investment_asset,
      Ongoing_monitor:newRow.Ongoing_monitor, 
      //Remarks: newRow.Remarks, Result: newRow.Result, 
      //English_filename: newRow.English_filename, Chinese_filename: newRow.Chinese_filename, Acuris_English: newRow.Acuris_English, Create_time: newRow.Create_time, 
    },{
      headers: {
        "content-type": "application/json",
        Authorization: `${user.signInUserSession.idToken.jwtToken}`
      }
    })
    .then((res) => {
      if (res.status === 204 || res.status === 201) {
        setSnackbar({ children: 'Customer: ' + newRow.Customer + ' Name: ' + newRow.English_name + '  ' + newRow.Chinese_name + ' attribute updated successfully. ', severity: 'success' });
      }
      else {
        setSnackbar({ children:  'Customer: ' + newRow.Customer + ' Name: ' + newRow.English_name + '  ' + newRow.Chinese_name + ' attribute updates failed.', severity: 'error' });
      }
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 403) {
        //signOut();
        //navigate("/login");
        window.location.reload(true);
      }
      console.log(err);
      setSnackbar({ children:  'Customer: ' + newRow.Customer + ' Name: ' + newRow.English_name + '  ' + newRow.Chinese_name + ' attribute updates exception.', severity: 'error' });
    });
    return newRow;
  });

  const handleProcessRowUpdateError = React.useCallback((error) => {}, []);

  const [notes, setNotes] = useState([]);
  const [auditNotes, setAuditNotes] = useState([]);
  const [showLogTable, setShowLogTable] = useState(false);
  var showButton;

  const columns = [
    //{ field: 'User', headerName:'User', width: 150, hideable: false },
    //{ field: 'Case', headerName:'Case', disableExport: true, width: 150 },
    {
      field: "Action",
      headerName: "Action",
      description: "Actions",
      sortable: false,
      disableExport: true,
      width: 130,
      renderCell: (params) => {
        return (
          <>            
            <IconButton
              onClick={() => {
                handleReview(params.row);
              }}
              variant="contained"
              >
            <RateReviewIcon>Review</RateReviewIcon>
            </IconButton>
            <IconButton
              onClick={() => {
                handleFreshButton(params.row);
              }}
              variant="contained"
            >
            <RefreshIcon>Refresh</RefreshIcon>
            </IconButton>
            <IconButton
              onClick={() => {
                handleDeleteConfirmation(params.row);
              }}
              variant="contained"
            >
            <DeleteIcon>Delete</DeleteIcon>
            </IconButton>
          </>
        );
      },
    },
    (monitorFreq !== 'Disable') && { field: 'Ongoing_monitor', headerName: 'Ongoing_monitor', description:'Editable', width: 150, editable: true, type: 'boolean',
      renderHeader: () => (<strong> {'Ongoing_monitor'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Customer', headerName: 'Customer', width: 100, hideable: false },
    { field: 'Type', headerName: 'Type', width: 100, hideable: false },
    { field: 'English_name', headerName: 'English_name', width: 200, hideable: false },
    { field: 'Chinese_name', headerName: 'Chinese_name', width: 150, hideable: false },
    { field: 'Acuris_English', headerName: 'Acuris', disableExport: true, width: 110,
      renderCell: (params) => (
        <strong>
          {showButton = Boolean(params.value) && params.value.substring(0, 7) == "Result/"}
          {!showButton && Boolean(params.value) && params.value}
          {!showButton && !Boolean(params.value) && Boolean(params.row.English_name) && "processing..."}
          {showButton && 
            <Button 
              variant="contained" size="small"
              onClick={() => downloadSearchResult(params.row.Acuris_English)}
            >
              Download
            </Button>}
        </strong>
      )
    },
    enableGoogle && { field: 'English_filename', headerName: 'English_filename', disableExport: true, width: 110,
      renderCell: (params) => (
        <strong>
            {showButton = Boolean(params.value) && params.value.substring(0, 7) == "Result/"}
            {!showButton && Boolean(params.value) && params.value}
            {!showButton && !Boolean(params.value) && Boolean(params.row.English_name) && "processing..."}
            {showButton && (
              <Button 
                variant="contained" size="small"
                onClick={() => downloadSearchResult(params.row.English_filename)}
              >
                Download
              </Button>
            )}
        </strong>
      )
    },
    enableGoogle && { field: 'Chinese_filename', headerName: 'Chinese_filename', disableExport: true, width: 110,
      renderCell: (params) => (
        <strong>
          {showButton = Boolean(params.value) && params.value.substring(0,7) == "Result/"}
          {!showButton && Boolean(params.value) && params.value}
          {!showButton && !Boolean(params.value) && Boolean(params.row.Chinese_name) && "processing..."}
          {showButton && 
            <Button 
              variant="contained" size="small"
              onClick={() => downloadSearchResult(params.row.Chinese_filename)}
            >
              Download
            </Button>}
        </strong>
      )
    },    
    { field: 'Create_time', headerName: 'Create_time', width: 100, editable: true, disableExport: true, valueFormatter: (params) => {
        const utcDate = new Date(params.value);
        const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000);
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const day = String(localDate.getDate()).padStart(2, '0');
        const hours = String(localDate.getHours()).padStart(2, '0');
        const minutes = String(localDate.getMinutes()).padStart(2, '0');
        const seconds = String(localDate.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      },
    },
    { field: 'Nationality', headerName: 'Nationality', width: 100, type: 'singleSelect', editable: false, valueOptions:nationalityList.map(({ code }) => code)},
    { field: 'DOB', headerName: 'DOB', width: 100, editable: false, type: 'date',
      valueFormatter: (params) => {
        if (params && params.value) {
          return moment(params.value).format("YYYY-MM-DD");
        } else {
          return ""; 
        }
      },
    },
    { field: 'Gender', headerName: 'Gender', width: 100, type: 'singleSelect', valueOptions:["", "Male", "Female"], editable: false },
    { field: 'Email', headerName: 'Email', description:'Editable', width: 100, editable: true,
      renderHeader: () => (<strong> {'Email'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Contact_number', headerName: 'Contact_number', description:'Editable', width: 150, editable: true,
      renderHeader: () => (<strong> {'Contact_number'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Mobile', headerName: 'Mobile', description:'Editable', width: 100, editable: true, 
      renderHeader: () => (<strong> {'Mobile'} <span role="img" aria-label="Editable">📝</span> </strong>),
    }, 
    { field: 'IDType', headerName: 'IDType', description:'Editable', width: 100, editable: true, 
      renderHeader: () => (<strong> {'IDType'} <span role="img" aria-label="Editable">📝</span> </strong>),
    }, 
    { field: 'IDNumber', headerName: 'IDNumber', description:'Editable', width: 100, editable: true, 
      renderHeader: () => (<strong> {'IDNumber'} <span role="img" aria-label="Editable">📝</span> </strong>),
    }, 
    { 
      field: 'IDExpiry', 
      headerName: 'IDExpiry', 
      description: 'Editable', 
      width: 100, 
      editable: true, 
      type: 'date',
      valueFormatter: params => {
        if (params && params.value) {
          return moment(params.value).format("YYYY-MM-DD");
        } else {
          return ""; 
        }
      },
      renderHeader: () => (<strong> {'IDExpiry'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Residential_address', headerName: 'Residential_address', description:'Editable', width: 170, editable: true,
      renderHeader: () => (<strong> {'Residential_address'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Permanent_address', headerName: 'Permanent_address', description:'Editable', width: 170, editable: true,
      renderHeader: () => (<strong> {'Permanent_address'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Correspondence_address', headerName: 'Correspondence_address', description:'Editable', width: 200, editable: true,
      renderHeader: () => (<strong> {'Correspondence_address'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Tax_resident_country', headerName: 'Tax_resident_country', description:'Editable', width: 190, type: 'singleSelect', editable: true, valueOptions:nationalityList.map(({ code }) => code),
      renderHeader: () => (<strong> {'Tax_resident_country'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Tax_number', headerName: 'Tax_number', description:'Editable', width: 120, editable: true,
      renderHeader: () => (<strong> {'Tax_number'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Tax_type', headerName: 'Tax_type', description:'Editable', width: 100, editable: true,
      renderHeader: () => (<strong> {'Tax_type'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Tax_country', headerName: 'Tax_country', description:'Editable', width: 120, editable: true, type: "singleSelect", valueOptions: nationalityList.map(({ code }) => code),
      renderHeader: () => (<strong> {'Tax_country'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Annual_income_source_1', headerName: 'Annual_income_source_1', description:'Editable', width: 200, editable: true,
      renderHeader: () => (<strong> {'Annual_income_source_1'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Annual_income_source_2', headerName: 'Annual_income_source_2', description:'Editable', width: 200, editable: true,
      renderHeader: () => (<strong> {'Annual_income_source_2'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Annual_income_source_3', headerName: 'Annual_income_source_3', description:'Editable', width: 200, editable: true,
      renderHeader: () => (<strong> {'Annual_income_source_3'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Source_of_wealth_1', headerName: 'Source_of_wealth_1', description:'Editable', width: 180, editable: true,
      renderHeader: () => (<strong> {'Source_of_wealth_1'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Source_of_wealth_2', headerName: 'Source_of_wealth_2', description:'Editable', width: 180, editable: true,
      renderHeader: () => (<strong> {'Source_of_wealth_2'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Source_of_wealth_3', headerName: 'Source_of_wealth_3', description:'Editable', width: 180, editable: true,
      renderHeader: () => (<strong> {'Source_of_wealth_3'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Currency_1', headerName: 'Currency_1', description:'Editable', width: 100, editable: true, type: "singleSelect", valueOptions: currencyList.map(({ code }) => code),
      renderHeader: () => (<strong> {'Currency_1'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Currency_2', headerName: 'Currency_2', description:'Editable', width: 100, editable: true, type: "singleSelect", valueOptions: currencyList.map(({ code }) => code),
      renderHeader: () => (<strong> {'Currency_2'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Currency_3', headerName: 'Currency_3', description:'Editable', width: 100, editable: true, type: "singleSelect", valueOptions: currencyList.map(({ code }) => code),
      renderHeader: () => (<strong> {'Currency_3'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Estimated_networth_1', headerName: 'Estimated_networth_1', description:'Editable', width: 180, editable: true, type: 'number',
      renderHeader: () => (<strong> {'Estimated_networth_1'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Estimated_networth_2', headerName: 'Estimated_networth_2', description:'Editable', width: 180, editable: true, type: 'number',
      renderHeader: () => (<strong> {'Estimated_networth_2'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Estimated_networth_3', headerName: 'Estimated_networth_3', description:'Editable', width: 180, editable: true, type: 'number',
      renderHeader: () => (<strong> {'Estimated_networth_3'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Employer_name', headerName: 'Employer_name', description:'Editable', width: 150, editable: true,
      renderHeader: () => (<strong> {'Employer_name'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Occupation', headerName: 'Occupation', description:'Editable', width: 150, editable: true,
      renderHeader: () => (<strong> {'Occupation'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Position', headerName: 'Position', description:'Editable', width: 100, editable: true,
      renderHeader: () => (<strong> {'Position'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
    { field: 'Proof_of_professional_investment_asset', headerName: 'Proof_of_professional_investment_asset', description:'Editable', width: 300, editable: true, type: 'number',
      renderHeader: () => (<strong> {'Proof_of_professional_investment_asset'} <span role="img" aria-label="Editable">📝</span> </strong>),
    },
  ].filter(Boolean); // This will remove falsy values (i.e., undefined) from the array
  const importColumns = [
    //{ field: 'User', headerName:'User', width: 150, hideable: false },
    //{ field: 'Case', headerName:'Case', disableExport: true, width: 150, hideable: true },
    { field: 'Upload_time', headerName: 'Upload Time', width: 180, valueFormatter: (params) => {
        const utcDate = new Date(params.value);
        const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000);
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const day = String(localDate.getDate()).padStart(2, '0');
        const hours = String(localDate.getHours()).padStart(2, '0');
        const minutes = String(localDate.getMinutes()).padStart(2, '0');
        const seconds = String(localDate.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      },
    },
    { field: 'Import_filename', headerName: 'Import File', disableExport: true, width: 110,
      renderCell: (params) => (
        <strong>
          {showButton = Boolean(params.value) && params.value.substring(0,7) == "Import/"}
          {!showButton && Boolean(params.value) && params.value}
          {!showButton && !Boolean(params.value) && "processing..."}
          {showButton && 
            <Button 
              variant="contained" size="small"
              onClick={()=>downloadSearchResult(params.row.Import_filename)}
            >
              Download
            </Button>}
        </strong>
      )
    },
    { field: 'Import_log', headerName: 'Import Log', disableExport: true, width: 110,
      renderCell: (params) => (
        <strong>
          {showButton = Boolean(params.value) && params.value.substring(0,7) == "Import/"}
          {!showButton && Boolean(params.value) && params.value}
          {!showButton && !Boolean(params.value) && "processing..."}
          {showButton && 
            <Button 
              variant="contained" size="small"
              onClick={()=>downloadSearchResult(params.row.Import_log)}
            >
              Download
            </Button>}
        </strong>
      )
    },
    { field: 'Pass_number', headerName: 'Pass number', width: 150 },
    { field: 'Import_case_total', headerName: 'Import Total', width: 150}
  ];
  const urlstr = `${config.api.invokeUrl}`;

  const downloadSearchResult = async (filename) => {
    if (filename == null) return;
    console.log("download");
    const link = document.createElement("a");
    var axios = require('axios');
    var config = {
      method: 'get',
      url: urlstr + `/kycic-search-result?file=` + filename,
      headers: {
        "content-type": "application/json",
        Authorization: `${user.signInUserSession.idToken.jwtToken}`
      }
    };
    
    axios(config)
    .then(function (response) {
      const Url = response.data.URL;
      window.open(Url);
    })
    .catch(function (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        //signOut();
        //navigate("/login");
        window.location.reload(true);
      }
      console.log(error);
    });
  };
  
  const fetchCases = async () => {
    setIsLoading(true);
    try {
      const requestInfo = {
        headers: {
          "content-type": "application/json",
          Authorization: `${user.signInUserSession.idToken.jwtToken}`
        },
        params:{"User":`${user.attributes.email}`}
      }
      const res = await axios.get(`${config.api.invokeUrl}`, requestInfo);
      const Cases = res.data;
      setNotes(Cases);
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        //signOut();
        //navigate("/login");
        window.location.reload(true);
      }
      console.log(`An error has occurred: ${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      fetchCases(); // Your existing function
  
      const interval = setInterval(() => {
        fetchCases();
      }, 1 * 60 * 1000);
  
      return () => clearInterval(interval);
    };
  
    const fetchProfileData = async () => {
      try {
        // Make an API call to get the profile data
        if (user) {
          const response = await axios.get(
            `${config.api.invokeUrl}/profile`,
            {
              headers: {
                "content-type": "application/json",
                Authorization: `${user.signInUserSession.idToken.jwtToken}`,
              },
            }
          );
  
          if (response.status === 200) {
            const profileData = response.data;
            setUserEmail(profileData.User_email || '');
            setAdmin(profileData.Admin);
            setEnableGoogle(profileData.Enable_Google);
            setMonitorFreq(profileData.Monitor_freq);
          }
        }
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          //signOut();
          //navigate("/login");
          window.location.reload(true);
        }
        console.error('Error fetching profile data in layout:', error);
        setSnackbar({
          children: 'Fail to fetch the profile data in layout',
          severity: 'warning',
        });
      }
    };
  
    fetchData(); // Call the existing fetchData function
    fetchProfileData(); // Call the new fetchProfileData function  
  }, [user, isAdmin, enableGoogle])

  const fetchAuditCases = async () => {
    try {
      const requestInfo = {
        headers: {
          "content-type": "application/json",
          Authorization: `${user.signInUserSession.idToken.jwtToken}`
        },
        params:{"User":`${user.attributes.email}`}
      }
      const res = await axios.get(`${config.api.invokeUrl}/auditimport`, requestInfo);
      const Cases = res.data;
      setAuditNotes(Cases);
    } catch (err) {  
      if (err.response.status === 401 || err.response.status === 403) {
        //signOut();
        //navigate("/login");
        window.location.reload(true);
      }
      console.log(`An error has occurred: ${err}`);
    }
  }
  useEffect(() => {
    fetchCases()
    fetchAuditCases();
    const interval = setInterval(() => {
      fetchCases()
      fetchAuditCases();
    }, 1 * 60 * 1000);
    return() => clearInterval(interval);
  }, []);

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [logFile, setLogFile] = useState(null);

  const onFileChange = (event) => {
    console.log("upload");    
    setSelectedFile(event.target.files[0]);
    const formData = new FormData();
    //var date = new Date();
    //var file_name = date.toISOString().slice(0, 19).replace(/[^0-9]/g, "") + '-' + user.attributes.email + '-' + event.target.files[0].name;
    var file_name = event.target.files[0].name;
    if (event.target.files[0].name.slice(-4) == '.csv' && !/[#-]/.test(file_name)) {
      formData.append(user.attributes.email, event.target.files[0], file_name);
      //var axios = require('axios');
      axios({
        method: "post",
        url: urlstr,
        data: formData,
        headers: {
          "content-type": "application/json",
          Authorization: `${user.signInUserSession.idToken.jwtToken}`
        }
      }).then(() => {
        setLogFile('Import/' + file_name.slice(0, -3) + 'log');
        setSelectedFile(null);
        setFileUploaded(true);
        setShowLogTable(true);
        fetchAuditCases();
      });
    } else if (event.target.files[0].name.slice(-5) == '.xlsx' && !/[#-]/.test(file_name)) {
      formData.append('file', event.target.files[0]);
      axios.post(urlstr + '/binary-upload', formData, {
        headers: {
          'Accept': 'multipart/form-data',
          'Content-Type': 'multipart/form-data',
          Authorization: `${user.signInUserSession.idToken.jwtToken}`, 
        },
      })
    } else {
        setSnackbar({ children:  'Import file: ' + file_name + ' is not a .csv or .xlsx file or contains hyphen or hash.', severity: 'error' });
    }
  };

  function CustomToolbar() {
    const generateFilename = () => {
      const now = new Date();
      const formattedDate = `${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}`;
      return `KYCIC-Export-${userEmail}-${formattedDate}`;
    };
    const fileName = generateFilename(); 
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ utf8WithBom: true, allColumns: true, fileName: fileName }} />
        <div style={{ flex: 1 }} />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }  

  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const onTemplateDownload = () => {
    const link = document.createElement("a");
    link.download = `KYCIC app import template.xlsx`;
    link.href = "KYCIC app import template.xlsx";
    link.click();
    setSnackbar({ children:  'Download completed. Note Import feature supports both CVS UTF-8 (Comma delimited) file and Excel Workbook.', severity: 'success' });
  };
  return (
    <Box sx={{ margin:"auto", height: 'auto', width: '92vw' }}>
      {isLoading && <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
      <DataGrid
        initialState={{
          sorting: { sortModel: [{field:'Create_time', sort: 'desc'}], },
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        disableRowSelectionOnClick
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        rows={notes} 
        pageSizeOptions={[10, 25, 50]}
        columns={columns} 
        getRowId={(row) => row.Case} 
        slots={{ toolbar: CustomToolbar }}
        getRowHeight={() => 'auto'}
        autoHeight
      />

      {isAdmin && (
        <>
          <input style={{ display: "none" }} id="select-upload-csv" type="file" 
            onChange={onFileChange}
            onClick={(event) => {event.target.value = null;}} 
          />
          <label htmlFor="select-upload-csv">
            <Button sx={{ mt: 1, mx: 1 }} variant="text" startIcon={<FileUploadOutlinedIcon/>} component="span">Import</Button>
          </label>
          <Button sx={{ mt: 1, mx: 1 }} onClick={onTemplateDownload} variant="contained" size="small" endIcon={<RecentActorsIcon />}>Download Import file template </Button>
          <Button sx={{ mt: 1, mx: 1 }} variant="contained" size="small" endIcon={showLogTable ? <VisibilityOffIcon/> : <VisibilityIcon/>} onClick={() => setShowLogTable(!showLogTable)}>{showLogTable ? 'Hide Import Log table' : 'Show import log table'}</Button>
          {showLogTable && <Box sx={{ margin:"auto", paddingTop: 2, height: 388, width: '100%' }}>
            <DataGrid 
              rows={auditNotes} 
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
                sorting: {
                  sortModel: [{ field: 'Upload_time', sort:'desc'}]
                }
              }}
              pageSizeOptions={[10, 25, 50]}
              columns={importColumns} 
              getRowId={(row) => row.Case}
              disableRowSelectionOnClick
              autoHeight        
            />
          </Box>}
        </>
      )}
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete Case confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the following Case? <br/><br/>
              Customer: {deletingRow && deletingRow.Customer} <br/>
              English name: {deletingRow && deletingRow.English_name} {deletingRow && deletingRow.English_name !== '' && (typeof(deletingRow.English_filename) == 'undefined' || deletingRow.English_filename === 'processing...') && 'is PROCESSING!'}<br/>
              Chinese name: {deletingRow && deletingRow.Chinese_name} {deletingRow && deletingRow.Chinese_name !== '' && (typeof(deletingRow.Chinese_filename) == 'undefined' || deletingRow.Chinese_filename === 'processing...') && 'is PROCESSING!'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleConfirmationClose} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {!!snackbar && (
      <Snackbar
        open
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      >
        <Alert {...snackbar} onClose={handleCloseSnackbar} />
      </Snackbar>
      )}
    </Box>
  );
}
